<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>巡查管理</el-breadcrumb-item>
        <el-breadcrumb-item>自查自纠列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15">
      <div class="pt20" style="text-align: left">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-form-item>
            <el-input
              placeholder="企业名称"
              v-model="page.company_name"
              clearable
              class="input-with-select"
              @clear="onSubmit"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="page.is_check_self"
              clearable
              placeholder="全部"
              @change="onSubmit(page.is_check_self)"
            >
              <el-option
                v-for="(item, index) in statusOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- <el-row class="box search">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input placeholder="企业名称" v-model="page.company_name" clearable class="input-with-select"
                @clear="onSubmit">

              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-select v-model="page.is_check_self" clearable placeholder="全部" @change="onSubmit(page.is_check_self)">
                <el-option v-for="(item,index) in statusOptions" :key="index" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="tl">
            <el-form-item size="medium">
              <el-button type="primary" @click="onSubmit" class="general_bgc general_border cfff">查询</el-button>
            </el-form-item>
          </el-col>

        </el-form>
      </el-row> -->
      <div class="box list">
        <el-table border :data="tableData" style="width: 100%">
          <el-table-column
            type="index"
            align="center"
            width="60"
            label="序号"
          ></el-table-column>
          <el-table-column
            prop="company_name"
            min-width="150"
            :show-overflow-tooltip="true"
            label="企业名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop=""
            min-width="150"
            :show-overflow-tooltip="true"
            label="地址"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.province }}{{ scope.row.city }}{{ scope.row.area
              }}{{ scope.row.town }}{{ scope.row.vill }}{{ scope.row.address }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="user_name_add" min-width="150" :show-overflow-tooltip="true" label="操作人" align='center'></el-table-column> -->
          <el-table-column
            prop="last_check_self_time"
            min-width="150"
            :show-overflow-tooltip="true"
            label="上次自查自纠时间"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            class-name="aa"
            min-width="150"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib"
                size="mini"
                @click="godetail(scope.row.company_id)"
                >详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { local, aa, session } from "../../../util/util";
import Globle from "@/components/Globle";
import { mapState } from "vuex";
export default {
  data() {
    return {
      page: {
        pager_offset: "0",
        pager_openset: "10",
        company_name: "",
        is_check_self: "1",
      },
      page1: {
        company_code: "",
        checkself_code_list: [],
      },
      selected: "",
      options4: "",
      options2: "",
      activeClass: 0,
      statusOptions: [
        { label: "全部", value: "" },
        { label: "已自查自纠", value: "1" },
        { label: "未自查自纠", value: "2" },
      ],
      currentPage: 1,
      total: 0,
      count: "",
      tableData: [],
      multipleSelection: [],
      allSelect: false,
    };
  },
  computed: {
    ...mapState({
      checkSelfPage1: (state) => state.checkSelf.checkSelfPage1,
    }),
  },
  created() {
    var that = this;
    that.page = Object.assign({}, that.checkSelfPage1);
    that.currentPage = that.page.pager_offset / 10 + 1;
    that.activeClass = String(that.page.ischeckself) - 1;
    that.init(that.page);
  },
  methods: {
    init(params) {
      var that = this;
      axios
        .get("/pc/company-cs/list", params)
        .then((v) => {
          that.tableData = v.data.company_list;
          that.total = v.pager_count;
        })
        .catch(() => {});
      // axios.get('/api/companytag/all', '').then((v) => {
      //   that.options2=v;
      // }).catch(() => {})
    },
    change(index, val) {
      var that = this;
      if (index == 1) {
        that.page.companytag_code = val;
      }
      this.onSubmit();
    },
    // 搜索
    onSubmit() {
      var that = this;
      that.currentPage = 1;
      that.page.pager_offset = "0";
      that.$store.dispatch("checkSelf/setNewPage1", that.page);
      that.init(that.page);
    },
    // 分页
    currentChange(page) {
      var that = this;
      that.currentPage = page;
      that.page.pager_offset = String((page - 1) * 10);
      that.$store.dispatch("checkSelf/setNewPage1", that.page);
      that.init(that.page);
    },

    godetail(index) {
      session.set("params1", { id: index, name: 1 });
      this.$router.push({
        name: "CheckSelf",
      });
    },
    // IsActive(k,val) {
    //   var that=this;
    //     this.activeClass = k;
    //        this.page.ischeckself=val;
    //     that.$store.dispatch('checkSelf/setNewPage', that.page)
    //   that.init(that.page)
    //   },
    handleConfirm() {
      let item = this.tableData[this.selected];
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map((item) => item.company_code);
    },
    toggleSelect(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row, !this.allSelect);
        });
        this.allSelect = !this.allSelect;
      }
    },
    send(index, val) {
      if (index == 0) {
        this.page1.company_code = val;
        this.page1.checkself_code_list = [];
        this.getsend(this.page1);
      } else {
        if (this.multipleSelection.length == 0) {
          this.$notify({
            title: "提示",
            message: "请选择企业",
          });
        } else {
          this.page1.company_code = "";
          this.page1.checkself_code_list = this.multipleSelection;
          this.getsend(this.page1);
        }
      }
    },
    getsend(params) {
      axios
        .post("/api/checkSelf/saveMessLogByNotCS", params)
        .then((v) => {})
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #2ec68a;
    border-color: #2ec68a;
    color: #ffffff;
  }
  .list_tab {
    padding: 10px;
    background-color: #f5f7fa;
    .list_tag {
      right: -10px;
      padding: 2px 6px;
      border-radius: 10px;
      top: -10px;
    }
  }
  /deep/ .el-checkbox__label {
    display: none !important;
  }
  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/.el-table .aa .cell {
    display: flex;
    justify-content: center;
  }
  /deep/ .el-table .disabledCheck .cell .el-checkbox__inner {
    display: none !important;
  }

  /deep/ .el-table .disabledCheck .cell::before {
    content: "选择";
    text-align: center;
    line-height: 37px;
  }
}
</style>
