<template>
  <div class="common">
    
  </div>
</template>

<script type="text/javascript">
import { local } from '../util/util'
var aa=local.get('user').menu_btns
const menu_btns=local.get('user').menu_btns!=null?local.get('user').menu_btns.split(','):null;


export default {
  menu_btns
};
</script>

